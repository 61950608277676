.App {
  text-align: center;
}

/** Chess grinder site styles */

.btn {
  @apply py-1;
  @apply px-3;
  @apply text-center;
}

.btn-sm {
  @apply py-1;
  @apply px-1;
  @apply text-center;
}

.btn-primary {
  @apply btn;
  @apply bg-primary-400;
  @apply text-black
}

.btn-dark {
  @apply btn;
  @apply bg-black;
  @apply text-white;
}

.btn-danger {
  @apply btn;
  @apply bg-danger-800;
  @apply text-white;
}

.btn-light {
  @apply btn;
  @apply bg-gray-200;
  @apply text-black;
}

.btn-unsupported {
  @apply btn;
  @apply text-gray-600;
  @apply line-through;
  @apply border;
}

.input-text {
  @apply border-b;
}

.bg-stripes-45-primary-400 {
  background: repeating-linear-gradient(
          45deg,
          theme("colors.primary.400"),
          theme("colors.primary.400") 10px,
          theme("colors.primary.300") 10px,
          theme("colors.primary.300") 15px,
          theme("colors.primary.400") 15px
  );
}

.bg-stripes-45-primary-200 {
  background: repeating-linear-gradient(
          45deg,
          theme("colors.primary.200"),
          theme("colors.primary.200") 10px,
          theme("colors.primary.100") 10px,
          theme("colors.primary.100") 15px,
          theme("colors.primary.200") 15px
  );
}

.bg-stripes-45-gray-200 {
  background: repeating-linear-gradient(
          45deg,
          theme("colors.gray.200"),
          theme("colors.gray.200") 10px,
          theme("colors.gray.50") 10px,
          theme("colors.gray.50") 15px,
          theme("colors.gray.200") 15px
  );
}

/* Hide the reCAPTCHA badge */
/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge {
    visibility: hidden;
}
